@media only screen and (max-width:1199px) {
    .front-site .logo-head { max-width: 315px; }
    body.front-site.nav-open .main-nav-wrap { left: 0; }
    .front-site .main-nav-wrap > ul > li +li { margin-left: 10px; }
    .front-site .header-main { /*padding: 20px 0;*/ background: #fff; }
    .front-site .main-nav-wrap > ul { font-size: 16px; }
    .front-site .page-banner-content .heading-v-1 { font-size: 38px; margin-bottom: 15px; }
    .front-site .page-banner-content p { font-size: 18px; }
    .front-site .page-banner-content { padding: 20px; margin-bottom: 20px; }
    .front-site li.logo-1 img, .front-site li.logo-3 img { max-height: 45px; }
    .front-site li.logo-2 img { max-height: 65px; }
    .front-site .logo-list-wrap li+li { margin-left: 25px; }
    .front-site .heading-v-1 { font-size: 32px; margin-bottom: 22px; }
    .front-site .page-heading p { font-size: 22px; }
    .front-site .footer-wrap { padding: 30px 0; }
    .front-site .footer-wrap p { font-size: 16px; }
    .front-site .social-icon img { max-height: 40px; }
    .front-site .social-icon li+li { margin-left: 20px; }
    .front-site .footer-wrap .link-list a { font-size: 14px; }
    .front-site .footer-wrap h6 { font-size: 16px; }
    .front-site .copyright-wrap { padding: 10px 0; }
    .front-site .multiple-join-wrap { background-image: none !important; }
    .front-site .form-box { padding: 30px 40px; }
    .front-site .form-box h4 { font-size: 22px; margin: 0 0 20px 0; }
    .front-site .form-group > label { font-size: 20px; }
    .front-site .form-group { margin-bottom: 20px; }
    .front-site .form-box hr { margin: 30px 0; }
    .front-site .custom-checkbox { font-size: 18px; }
    .front-site .custom-control-label::before, .front-site .custom-control-label::after { width: 22px; height: 22px; }
    .front-site .btn-primary, .front-site .btn-secondary { font-size: 18px; }
    .front-site .btn-primary+.btn-secondary { margin-left: 10px; }
    .front-site .btn-large { font-size: 20px; }
    }
     @media only screen and (max-width:991px) {
    .front-site .main-nav-wrap { position: fixed; width: 300px; left: -300px; transition: all 300ms; padding: 15px; z-index: 9; background: #fff; height: calc(100% - 77px); top: 77px; overflow-y: auto; }
    .front-site .main-nav-wrap > ul { flex-direction: column; }
    .front-site .main-nav-wrap > ul > li +li { margin-left: 0; }
    .front-site #toogle-btn { display: block; min-width: 20px; width: 20px; height: 14px; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); -webkit-transition: 0.5s ease-in-out; -moz-transition: 0.5s ease-in-out; -o-transition: 0.5s ease-in-out; transition: 0.5s ease-in-out; cursor: pointer; }
    .front-site #toogle-btn .bar { display: block; position: absolute; height: 2px; width: 100%; background: #212529; -webkit-border-radius: 9px; -moz-border-radius: 9px; border-radius: 9px; opacity: 1; left: 0; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); -webkit-transition: 0.25s ease-in-out; -moz-transition: 0.25s ease-in-out; -o-transition: 0.25s ease-in-out; transition: 0.25s ease-in-out; }
    .front-site #toogle-btn .bar-1 { top: 0px; }
    .front-site #toogle-btn .bar-2 { top: 6px; -webkit-animation: nav-icon-bg 3s infinite; -moz-animation: nav-icon-bg 3s infinite; -o-animation: nav-icon-bg 3s infinite; animation: nav-icon-bg 3s infinite; }
    .front-site #toogle-btn .bar-3 { top: 12px; }
    .nav-open.front-site  #toogle-btn .bar-1 { top: 6px; -webkit-transform: rotate(135deg); -moz-transform: rotate(135deg); -o-transform: rotate(135deg); -ms-transform: rotate(135deg); transform: rotate(135deg); }
    .nav-open.front-site  #toogle-btn .bar-2 { opacity: 0; left: -60px; }
    .nav-open.front-site  #toogle-btn .bar-3 { top: 6px; -webkit-transform: rotate(-135deg); -moz-transform: rotate(-135deg); -o-transform: rotate(-135deg); -ms-transform: rotate(-135deg); transform: rotate(-135deg); }
    .front-site .multiple-join-wrap { padding: 30px 0; }
    .front-site .join-bx-icon { height: 35px; margin-bottom: 10px; }
    .front-site .join-bx-icon img { max-height: 35px; }
    .front-site .join-bx { padding: 15px 2px; }
    .front-site .join-bx-detail h6 { font-size: 15px; }
    .front-site .join-bx-detail p { font-size: 12px; }
    .front-site .heading-v-1 { font-size: 28px; margin-bottom: 18px; }
    .front-site .page-heading p { font-size: 20px; }
    .front-site .join-bx-detail h6 { font-size: 14px; }
    .front-site .form-group > label { font-size: 18px; }
    .front-site .form-box { padding: 20px 20px; }
    .front-site .profile-change { max-width: 150px; }
    .front-site .profile-picture { height: 100px; }
    .front-site .profile-chnage-text { font-size: 14px; padding: 12px; }
    .front-site .form-group { margin-bottom: 15px; }
    .front-site .form-group > .form-control, .front-site .react-datepicker-wrapper .form-control { padding: 8.5px; }
    .front-site .register-wrap { padding: 30px 0; }
    .front-site .sec-ptb {
        padding: 30px 0;
    }
    }
     @media only screen and (max-width:767px) {
        .front-site .header-main { padding: 15px 0; }
        .front-site .logo-head { max-width: 215px; }
        .front-site .main-nav-wrap { top: 56px; height: calc(100% - 56px); }
        .front-site .join-col-left { margin-bottom: 18px; }
        .front-site .heading-v-1 { font-size: 26px; margin-bottom: 15px; }
        .front-site .page-heading p { font-size: 18px; }
        .front-site .main-nav-wrap > ul { font-size: 14px; }
        .front-site .page-banner-content .heading-v-1 { font-size: 32px; }
        .front-site .page-banner-content p { font-size: 16px; }
        .front-site .social-icon { margin-bottom: 15px !important; }
        .front-site .footer-wrap p { font-size: 14px; }
        .front-site .social-icon img { max-height: 30px; }
        .front-site .social-icon li+li { margin-left: 12px; }
        .front-site .row-links [class*="col-"]+[class*="col-"] {margin-top: 20px;}
        .front-site li.logo-1 img, .front-site li.logo-3 img { max-height: 40px; }
        .front-site li.logo-2 img { max-height: 55px; }
        .front-site .join-bx-icon img { max-height: 30px; }
        .front-site .join-bx-icon { height: 35px; margin-bottom: 8px; }
        .front-site .form-group > label { font-size: 16px; }
        .front-site .custom-control-label::before, .front-site .custom-control-label::after { width: 18px; height: 18px; left: -25px; }
        .front-site .custom-checkbox { font-size: 16px; padding-left: 25px; }
        .front-site .btn-primary, .front-site .btn-secondary { font-size: 16px; min-width: 120px; padding: 12px; }
        .front-site .btn-large { font-size: 18px; padding: 15px 30px; }
        .front-site .portal-login {padding: 40px 0;}
        .front-site .search-form [class*="col-"] .form-control {margin-bottom: 15px;}
        .front-site .search-form [class*="col-"]:last-child .form-control {margin-bottom: 0;}
        .front-site .color-divider img {height: 10px;width: 100%;}

    }
     @media only screen and (max-width:567px) {
        .front-site .heading-v-1 { font-size: 24px; margin-bottom: 15px; }
        .front-site .main-nav-wrap { width: 260px; left: -260px; }
        .front-site .page-banner-content .heading-v-1 { font-size: 28px; }
        .front-site .page-heading p { font-size: 16px; }
        .front-site .join-bx-detail p { font-size: 11px; }
        .front-site .join-col-left .row [class*="col-"], .front-site .join-col-right .row [class*="col-"] { padding: 0 3px; }
        .front-site .join-col-left .row, .front-site .join-col-right .row { margin: 0 -3px; }
        .front-site .btn-primary, .front-site .btn-secondary { min-width: 80px; }
    }
     @media only screen and (max-width:367px) {
        .front-site .join-col-left .row [class*="col-"], .front-site .join-col-right .row [class*="col-"] { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
    }
    